<template>
  <div id="projects-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link class="btn btn-primary mb-5" to="/project/create">Neues Projekt starten</router-link>

    <!--<p>Todo:</p>
    <ul>
      <li>Further categorize Items</li>
      <li>Make more Content</li>
      <li>Connect final Items to content and tools</li>
      <li>Generate i18n</li>
    </ul>-->

    <IntroBanner v-if="!(projectSelection && projectSelection.length)" link="/project/create"></IntroBanner>

    <div v-for="(project, index) in projectSelection" :key="index" class="card mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-2">
                <div v-if="project.profile.logo" class="avatar-wrapper">
                  <img :src="project.profile.logo" alt="" class="img-fluid avatar">
                </div>
                <div v-else class="color-orb" :style="'background:' + project.settings.themeColor"></div>
              </div>
              <div class="col-10">
                  <h3>
                    <i v-if="project.deleted" class="fad fa-trash"></i>
                    <i v-else-if="!projectOwned(project)" class="fad fa-bug"></i>
                    {{ project.profile.projectName }}
                  </h3>
                  <p class="h4 mb-0"><code>{{ project.profile.rootUrl }}</code></p>
              </div>
            </div>
            <hr>
            <div class="form-group">
              <h4>Ansprechpartner</h4>
              <p>{{ project.profile.firstName }} {{ project.profile.lastName }}</p>
            </div>
          </div>
          <div class="col-sm-6">
            <h4>Team</h4>
            <div v-for="(member, index) in project.members" class="form-group" :key="index">
              <div class="row align-items-center">
                <div class="col-2">
                  <div v-if="member.logo" class="avatar-wrapper">
                    <img :alt="member.email + ' Profilbild'" :src="member.logo" class="img-fluid avatar">
                  </div>
                  <div v-else-if="member.settings" class="color-orb" :style="'background:' + member.settings.chatColor"></div>
                </div>
                <div class="col-10">
                  <h5>{{ member.firstName || "Invite ausstehend" }} {{ member.lastName }}</h5>
                  <p class="text-muted mb-0">{{ member.email || "&lt;E-Mail&gt;" }}{{ member.telephone ? " / " + member.telephone : "" }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button @click="selectProject(project)" class="btn text-white mr-3" :style="'background:' + project.settings.themeColor">
          Zum Projekt-Profil
        </button>
      </div>
    </div>

  </div>
</template>

<script>

import IntroBanner from "@/components/IntroBanner";
export default {
  name: 'Projects',
  components: {IntroBanner},
  data() {
    return {
      view: 'base',
      inviteEmails: "",
      selectedProjectId: ""
    }
  },
  computed: {
    projectSelection() {
      return this.project.projectSelection;
    }
  },
  methods: {
    updateProjectByProp(project) {
      this.$store.dispatch('project/updateProjectByProp', {prop: "profile", data: project.profile});
      this.$store.dispatch('project/updateProjectByProp', {prop: "settings", data: project.settings});
    },
    invite() {
      let newMembers = this.inviteEmails.split(",").map(m => {
        return {
          email: m.trim()
        }
      });
      let members = this.project.members.concat(newMembers);
      this.$store.dispatch('project/updateProjectByProp', {prop: "members", data: members});
    },
    selectProject(project) {
      let route = '/project/' + project._projectId;
      this.$store.dispatch('project/switchProject', project._projectId)
          .then(res => {
            console.debug(res);
          })
          .catch(err => {
            console.debug(err);
          })
          .finally(() => {
            this.$router.push(route);
          });
    }
  },
  beforeMount() {
  }
}
</script>

<style lang="scss" scoped>
.color-orb {
  border-radius: 100%;
  height: 0;
  width: 100%;
  padding-top: 100%;
}
.avatar-wrapper {
  width: 100%;
  padding-top: 100%;
  margin: 0;
}
</style>